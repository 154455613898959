/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import "core-js"
import "regenerator-runtime/runtime"
import "unorm"

export const onClientEntry = () => {
  // Don't need to do anything here, but if you don't
  // export something, the import won't work.
}
