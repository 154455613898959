// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-product-js": () => import("/builds/webdev/free-to-play-games/fanhub-website-fe/src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-case-study-js": () => import("/builds/webdev/free-to-play-games/fanhub-website-fe/src/templates/case-study.js" /* webpackChunkName: "component---src-templates-case-study-js" */),
  "component---src-templates-client-js": () => import("/builds/webdev/free-to-play-games/fanhub-website-fe/src/templates/client.js" /* webpackChunkName: "component---src-templates-client-js" */),
  "component---src-templates-sport-js": () => import("/builds/webdev/free-to-play-games/fanhub-website-fe/src/templates/sport.js" /* webpackChunkName: "component---src-templates-sport-js" */),
  "component---src-pages-404-js": () => import("/builds/webdev/free-to-play-games/fanhub-website-fe/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/builds/webdev/free-to-play-games/fanhub-website-fe/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-clients-js": () => import("/builds/webdev/free-to-play-games/fanhub-website-fe/src/pages/clients.js" /* webpackChunkName: "component---src-pages-clients-js" */),
  "component---src-pages-contact-js": () => import("/builds/webdev/free-to-play-games/fanhub-website-fe/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-genius-holding-js": () => import("/builds/webdev/free-to-play-games/fanhub-website-fe/src/pages/geniusHolding.js" /* webpackChunkName: "component---src-pages-genius-holding-js" */),
  "component---src-pages-index-js": () => import("/builds/webdev/free-to-play-games/fanhub-website-fe/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("/builds/webdev/free-to-play-games/fanhub-website-fe/src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-products-js": () => import("/builds/webdev/free-to-play-games/fanhub-website-fe/src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-work-at-fanhub-js": () => import("/builds/webdev/free-to-play-games/fanhub-website-fe/src/pages/work-at-fanhub.js" /* webpackChunkName: "component---src-pages-work-at-fanhub-js" */),
  "component---src-pages-work-js": () => import("/builds/webdev/free-to-play-games/fanhub-website-fe/src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

